require('jquery');
/* ======================================================================================================
--------------------------------          MEGA MENU JS                                --------------------------------
===========================================================================================================*/
//dropdown menu on hover

// breakpoint and up

function megamenuhover() {
    // when you hover a toggle show its dropdown menu

    $("#main-menu .megamenu-li:not(.isMobile)").hover(function () {
        $("#main-menu .megamenu-li.show:not(.isMobile), #main-menu .dropdown-menu.show ").removeClass("show");
        $(this).addClass("show");
        $(this).find(".dropdown-menu").addClass("show");
    });
    //Fix megamenu leave
    /*$("#carouselBanner,section").click(function () {
				$("#main-menu .megamenu-li.show, #main-menu .dropdown-menu.show ").removeClass("show");
            });*/
    element = '#main-menu .dropdown-menu.show';
    $(document).on("click", function (event) {
        if (!$(event.target).closest("#main-menu .megamenu-li:not(.isMobile)").length) {
            $(element).removeClass("show");
            $("#main-menu .megamenu-li").removeClass('show');
        }
    });
    /* 	else
    	{
            $("#main-menu .megamenu-li").click(function () {
                $(this).toggleClass("show");
                $(this).find(".dropdown-menu").toggleClass("show");
            });
        } */
    return false;
}

/*
$( document ).ready(function() {
    //megamenuhover();
    $(window).on('load resize', function () {
        if ($(window).width() <= 768)
        {
            $("#main-menu .megamenu-li").addClass('isMobile');
            //$("#main-menu .megamenu-li span").attr('data-toggle','');
            //megamenuhover();
        }else{
            $("#main-menu .megamenu-li").removeClass('isMobile');
            //$("#main-menu .megamenu-li span").attr('data-toggle','dropdown');
            megamenuhover();
        }
    });
});
*/

/*
$(".navbar-toggler").click(function () {
    $('.nav-main .dropdown-menu').hide();
});
$("#main-menu .megamenu li, #main-menu .megamenu .dropdown-menu").mouseenter(function () {

    $('.nav-main .dropdown-menu').hide();
    if ($(this).hasClass('dropdown-menu')) {
        var id = $(this).attr('aria-labelledby');
    }
    if ($(this).hasClass('megamenu')) {
        var id = $(this).children('[data-toggle="dropdown"]').attr('id');
    }
    $("#main-menu .dropdown-menu[aria-labelledby='" + id + "']").show();

});


$("#main-menu .megamenu .dropdown-menu").mouseleave(function () {
    if ($(this).hasClass('dropdown-menu')) {
        var id = $(this).attr('aria-labelledby');
    }
    if ($(this).hasClass('megamenu')) {
        var id = $(this).children('[data-toggle="dropdown"]').attr('id');
    }
    $("#main-menu .dropdown-menu[aria-labelledby='" + id + "']").hide();
});
$("#main-menu li.notdropdown").mouseenter(function () {
    $("#main-menu .nav-main .dropdown-menu").hide();
});

*/
// block right menu hover

$(".submenu[data-hover]").hover(
    function () {
        var elem = $(this).attr('data-hover');
        $('[data-hover-block]').removeClass('fade').addClass('d-none');
        $('[data-hover-block="' + elem + '"]').removeClass('d-none');
    },
    function () {
        $('[data-hover-block]').removeClass('fade').addClass('d-none');
        $('[data-hover-block="default"]').removeClass('d-none');
    }
);

$(document).ready(function () {
    $('#assistant').on('change', function () {

        if ($(this).val() == "1") {
            $("#contact-products").show();
            //console.log("ddddd");
        } else {
            $("#contact-products").hide();
            //console.log("cccc");
        }
    });
    // product mpricing
    $('.to-mpricing').click(function () {
        var sectionTo = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(sectionTo).offset().top
        }, 1500);
    });
});



/*  Scripts for Bootstrap Carousel and Animate.css article */
(function ($) {
    //Function to animate slider captions
    function doAnimations(elems) {
        //Cache the animationend event in a variable
        var animEndEv = "webkitAnimationEnd animationend";

        elems.each(function () {
            var $this = $(this),
                $animationType = $this.data("animation");
            $this.addClass($animationType).one(animEndEv, function () {
                $this.removeClass($animationType);
            });
        });
    }

    //Variables on page load
    var $myCarousel = $("#carouselBanner"),
        $firstAnimatingElems = $myCarousel
        .find(".carousel-item:first")
        .find("[data-animation ^= 'animated']");

    //Initialize carousel
    $myCarousel.carousel();

    //Animate captions in first slide on page load
    doAnimations($firstAnimatingElems);

    //Other slides to be animated on carousel slide event
    $myCarousel.on("slide.bs.carousel", function (e) {
        var $animatingElems = $(e.relatedTarget).find(
            "[data-animation ^= 'animated']"
        );
        doAnimations($animatingElems);
    });
})(jQuery);
